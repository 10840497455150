import React from "react"

const consent = () => {
  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <h1
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
          textAlign: "center",
        }}
      >
        СОГЛАСИЕ
        <br />
        на обработку персональных данных
      </h1>
      <p
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
        }}
      >
        Пользователь, оставляя обращение, заявку на сайте
        https://energoalyans.com/&nbsp;(далее также – сайт), создавая аккаунт
        и/или соглашаясь с офертой на сайте, принимает настоящее Согласие на
        обработку персональных данных.
      </p>
      <p
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
        }}
      >
        Пользователь, действуя свободно, своей волей и в своём интересе,
        подтверждая свою дееспособность, даёт своё согласие&nbsp;
        <span
          style={{
            fontWeight: "bolder",
            fontFamily: '"Open Sans", Arial, sans-serif',
          }}
        >
          <i>ГК Энерноальянс </i>
        </span>
        на обработку своих персональных данных как с использованием, так и без использования
        средств автоматизации для целей обработки входящих запросов физических
        лиц (пользователей) с целью консультирования, направления комментариев
        физическим лицам (пользователям); аналитики действий физического лица
        (пользователя) на сайте и функционирования сайта; выполнения
        обязательств по договору оферты, принятому пользователем на сайте. При
        этом:
      </p>
      <ol
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
        }}
      >
        <li>
          Согласие предоставлено для использования моих следующих персональных
          данных: Фамилия, имя, отчество; номера контактных телефонов; адреса
          электронной почты; место работы и занимаемая должность; адрес;
          сведения о местоположении; тип, версия, язык операционной системы,
          браузера; тип устройства и разрешение его экрана; страницы,
          открываемые пользователем; ip-адрес.
        </li>
        <li>
          Обработка моих персональных данных может включать следующие действия:
          сбор, запись, систематизацию, накопление, хранение, уточнение
          (обновление, изменение), извлечение, использование, передачу
          (распространение, предоставление, доступ), обезличивание,
          блокирование, удаление, уничтожение.
        </li>
      </ol>
      <p
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
        }}
      >
        Настоящее согласие может быть отозвано путём направления субъектом
        персональных данных (пользователем) или его представителем письменного
        заявления по адресу:&nbsp;
        <span
          style={{
            fontWeight: "bolder",
            fontFamily: '"Open Sans", Arial, sans-serif',
          }}
        >
          <i>ГК Энергоальянс</i>
        </span>
        <strong>
          <em>&nbsp;</em>
        </strong>
        236016, г. Калининград, ул. Полоцкая 45, 4 этаж&nbsp;либо по адресу электронной
        почты:&nbsp;
        <b>
          <i>sales@energoalyans.com</i>
        </b>
        . В&nbsp;случае отзыва субъектом персональных данных (пользователем)
        согласия на обработку персональных данных&nbsp;
        <span
          style={{
            fontWeight: "bolder",
            fontFamily: '"Open Sans", Arial, sans-serif',
          }}
        >
          <i>ГК Энергоальянс</i>
        </span>
        &nbsp;вправе продолжить обработку таких персональных данных в случаях,
        предусмотренных пунктами 2-11 части 1 статьи 6, пунктами 2-10 части 2
        статьи 10, части 2 статьи 11 Федерального закона от 27.07.2006
        №&nbsp;152-ФЗ «О персональных данных».
      </p>
      <p
        style={{
          fontFamily: '"Open Sans", Arial, sans-serif',
        }}
      >
        Настоящее согласие предоставляется на неопределённый срок и действует
        весь период обработки персональных данных. Запросы относительно
        персональных данных могут быть направлены по электронному адресу:&nbsp;
        <b>
          <i>sales@energoalyans.com</i>
        </b>
        .
      </p>
    </div>
  )
}

export default consent
